import React from "react";
import '../../CSS/Whoami.css';
import { useNavigate } from "react-router-dom";

function Whoami() {
    const navigate = useNavigate();
    return (
        <div className="whoami-container">
            <h1 className="whoami-title">WHO AM I?</h1>
            <p className="whoami-description">
                {/* Add your description here */}
                Hi, I'm NEBHAN. I’ve got experience in game development and I’m also getting into creating manga-style stories.
                 On this website, I’ll be sharing what I’ve been working on—whether it's games, manga, or anything else that comes to mind. Glad to have you here! ❤
            </p>
            <div onClick={()=>navigate("/")} className="back-button">
                <span className="arrow">←</span>Back to Home 
            </div>
        </div>
    );
}

export default Whoami;
