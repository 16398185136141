// Components/Pages/Latest.js

import React from 'react';
import '../../CSS/Latest.css';


const posts = [
    {
      id: 1,
      profileImg: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'John Doe',
      username: '@johndoe',
      time: '2h',
      content: 'Just finished working on my latest project! Excited to share it soon!',
      images: [],
      likes: 120,
      comments: 45,
      shares: 10,
    },
    {
      id: 2,
      profileImg: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Jane Smith',
      username: '@janesmith',
      time: '4h',
      content: 'Loving the new features in the latest release!',
      images: [],
      likes: 80,
      comments: 20,
      shares: 5,
    },
    {
      id: 3,
      profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Alex Brown',
      username: '@alexbrown',
      time: '6h',
      content: 'Check out this cool update! No images, just text.',
      images: [],
      likes: 50,
      comments: 10,
      shares: 2,
    },
    {
        id: 4,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 5,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 6,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 7,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 8,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 9,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
      {
        id: 10,
        profileImg: 'https://randomuser.me/api/portraits/men/3.jpg',
        name: 'Alex Brown',
        username: '@alexbrown',
        time: '6h',
        content: 'Check out this cool update! No images, just text.',
        images: [],
        likes: 50,
        comments: 10,
        shares: 2,
      },
  ];
  
  function Latest() {
    return (
      <div className="latest-container">
        {posts.map((post) => (
          <div key={post.id} className="post">
            <div className="post-header">
              <img src={post.profileImg} alt={`${post.name}'s profile`} className="profile-img" />
              <div className="post-info">
                <div className="post-author-row">
                  <span className="post-author">{post.name}</span>
                  <span className="post-username">{post.username}</span>
                  <span className="post-time"> • {post.time}</span>
                </div>
              </div>
            </div>
            <div className="post-content">
              <p>{post.content}</p>
              {post.images.length > 0 && (
                <div className="post-images">
                  {post.images.map((img, index) => (
                    <img key={index} src={img} alt={`Post content ${index + 1}`} className="post-img" loading="lazy" />
                  ))}
                </div>
              )}
            </div>
            <div className="post-interactions">
              <span>{post.likes} Likes</span>
              <span>{post.comments} Comments</span>
              <span>{post.shares} Shares</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default Latest;