import React, { useEffect, useState } from 'react';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Latest from './Components/Pages/Latest';
import Games from './Components/Pages/Games';
import Whoami from './Components/Pages/whoAmI';

// Header component
function Header() {
  const navigate = useNavigate();
  return (
    <header>
      <div className='MainWebsiteLOGO'></div>
      <p>- NEBHAN`s Original Works - </p><div onClick={() => navigate("/Whoami")} className='HowAMI'></div>
    </header>
  );
}

// Footer component
function Footer() {
  return (
    <footer>
      <p>
        &copy; 2024 NAIPSYCHO- <br />
        All Rights Reserved To <span className="NEBHANFOOTERHIGHLIGHT">NEBHAN</span>.
      </p>
    </footer>
  );
}

// Tabs data with icon paths
const tabsData = [
  { id: 1, label: "Latest", path: "/latest", content: <Latest />, icon: { filled: "LatestFilled.svg", outlined: "LatestOutline.svg" } },
  { id: 2, label: "Games", path: "/games", content: <Games />, icon: { filled: "GameFilled.svg", outlined: "GameOutline.svg" } },
  // Add more tabs here if needed
];

// Horizontal Tabs Component
const HorizontalTabs = ({ activeTabIndex, handleTabClick }) => {
  return (
    <div className="tabs-container">
      <div className="tabs-wrapper">
        {tabsData.map((tab, index) => {
          const iconUrl = (activeTabIndex === index)
            ? require(`./ICONS/${tab.icon.filled}`)
            : require(`./ICONS/${tab.icon.outlined}`);

          return (
            <div
              key={tab.id}
              className={`tab-button ${activeTabIndex === index ? 'active' : ''}`}
              onClick={() => handleTabClick(index, tab.path)}
            >
              <img
                src={iconUrl}
                alt={`Icon for ${tab.label}`}
                className="tab-icon"
              />
              {tab.label}
              {activeTabIndex === index && <div className="underline"></div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function App() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [whoamii, setWhoamii] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setWhoamii("/Whoami" === window.location.pathname);
    const currentTab = tabsData.findIndex(tab => tab.path === window.location.pathname);
    if (currentTab !== -1) {
      setActiveTabIndex(currentTab);
    }
    const timer = setTimeout(() => setLoading(false), 1000); // Simulate loading delay
    return () => clearTimeout(timer);
  }, [window.location.pathname]);

  const handleTabClick = (index, path) => {
    setLoading(true);
    setActiveTabIndex(index);
    navigate(path);
  };

  return (
    <div className="App">
      <div id='background'></div>
      {whoamii ? (<Routes><Route path="/Whoami" element={<Whoami />} /></Routes>) : (
        <main>
          <Header/>
          <HorizontalTabs activeTabIndex={activeTabIndex} handleTabClick={handleTabClick} />
          {loading ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <div className="tab-content">
              <Routes>
                <Route path="/" element={<Navigate to="/latest" />} />  {/* Redirect from / to /latest */}

                {tabsData.map((tab, index) => (
                  <Route key={tab.id} path={tab.path} element={tab.content} />
                ))}
              </Routes>
            </div>
          )}
        </main>


      )}
      <Footer />
    </div>
  );
}

export default App;
